/**
 * Calculates number of minutes available for passed amount of money on balance
 * (excluding free minutes and actions)
 * @param balance
 * @param callRate
 * @param getWithSeconds
 * @returns Number of minutes
 */
export const getAvailableMinutes = (
  balance: number | null,
  callRate: number,
  getWithSeconds: boolean = false,
): number => {
  if (balance === null || balance < 0) {
    return 0;
  }

  const availableMinutes = balance / callRate;

  if (getWithSeconds) {
    return availableMinutes;
  }

  return Math.floor(availableMinutes);
};
