import { SessionType } from "@/autoGeneratedGlobalTypes";
import { MIN_SESSION_DURATION } from "@/constants";
import { isUserEligibleForImmediateCall } from "@/utils/commonUtils";
import { getAvailableMinutes } from "@/utils/moneyUtils";

import { AppointmentStatusEnum } from "./types";

/**
 * Returns flag showing if session is ready to be created
 * @param appointmentStatus
 * @returns
 */
export const isSessionReadyToBeCreated = (appointmentStatus: AppointmentStatusEnum | null) =>
  appointmentStatus
  && [AppointmentStatusEnum.PaymentSkipped, AppointmentStatusEnum.SessionStart]
    .includes(appointmentStatus);

export const areMoneyAndFreeMinutesEnoughForCall = (
  balanceAmount: number | null,
  callRate: number,
  freeMinutesCount: number,
  sessionType: SessionType,
  isTrialAllowed: boolean,
) => {
  if (!balanceAmount || balanceAmount <= 0) {
    return false;
  }

  const countFreeMinutesFlag = (sessionType === SessionType.VIDEO_CALL && isTrialAllowed)
  || (sessionType === SessionType.CHAT && isTrialAllowed);

  return (getAvailableMinutes(balanceAmount, callRate) + (countFreeMinutesFlag ? freeMinutesCount : 0))
    >= MIN_SESSION_DURATION;
};

/**
 * Calculates initial appiontment status
 * @param freeMinutesCount
 * @param isSuccess
 * @returns
 */
export const getInitialAppointmentStatus = (
  sessionType: SessionType,
  balanceAmount: number | null,
  callRate: number,
  freeMinutesCount: number,
  isTrialAllowed: boolean,
  isSuccess?: boolean,
  isFailure?: boolean,
): AppointmentStatusEnum => {
  if (isUserEligibleForImmediateCall(sessionType, freeMinutesCount, isTrialAllowed)) {
    return AppointmentStatusEnum.PaymentSkipped;
  }
  if (isSuccess) {
    return AppointmentStatusEnum.PaymentSuccess;
  }
  if (isFailure) {
    return AppointmentStatusEnum.PaymentFailed;
  }
  return areMoneyAndFreeMinutesEnoughForCall(
    balanceAmount,
    callRate,
    freeMinutesCount,
    sessionType,
    isTrialAllowed,
  )
    ? AppointmentStatusEnum.StartOrPayChoice
    : AppointmentStatusEnum.Payment;
};
