import {
  useCallback, useContext, useEffect, useRef,
} from "react";

import { useLazyQuery } from "@apollo/client";
import { navigate } from "gatsby";

import { SessionEndReason, SessionStatus, SessionType } from "@/autoGeneratedGlobalTypes";
import GlobalContext from "@/contexts/Global/GlobalContext";
import useCreateSession from "@/hooks/useCreateSession";
import { UtmAnalysisEventEnum, useManageUtmData } from "@/hooks/useManageUtmData";
import { useMarketingHook } from "@/marketing/marketingHook";
import { getSession as getSessionType, getSessionVariables } from "@components/chat/graphql/__generated__/getSession";
import { GET_SESSION } from "@components/chat/graphql/GET_SESSION";

import { EXPERT_UPDATE_PHONE_SESSION_INFO_PERIOD_MS } from "./constants";

/**
 * Incapsulates phone call logic
 * @returns
 */
const usePhoneCall = (
  createSuccessCallback = () => { },
  createFailCallback = (errorCodeList: number[]) => { },
  failCallback = (errorCodeList?: SessionEndReason[]) => { },
) => {
  const { marketingPhoneCallStartedHandler } = useMarketingHook();

  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const { setAlertStatus } = useContext(GlobalContext);
  const { saveUtmData } = useManageUtmData();
  const [
    getSession,
    {
      data: getSessionData,
      error: getSessionError,
      loading: getSessionLoading,
    },
  ] = useLazyQuery<getSessionType, getSessionVariables>(GET_SESSION, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (getSessionData && !getSessionError && !getSessionLoading) {
      const {
        id: sessionId,
        status,
        expert: receiver,
        statistics: {
          durationInMinutes,
          totalPrice,
        },
        endReason,
      } = getSessionData.getSession;

      // todo for phone calls: move status check to utils and reuse
      if (![SessionStatus.waiting, SessionStatus.in_progress].includes(status)) {
        if (timer.current) {
          clearInterval(timer.current);
        }
        if (status === SessionStatus.failed) {
          if (endReason === SessionEndReason.voice_mail_detected) {
            failCallback([endReason]);
          } else {
            failCallback();
          }
        } else {
          navigate("/chat/review", {
            state: {
              durationInMinutes,
              totalPrice,
              receiver,
              sessionID: sessionId,
              sessionType: SessionType.PHONE_CALL,
              isExpert: false,
            },
          });
        }
      }
    }
  }, [
    failCallback,
    getSessionData,
    getSessionError,
    getSessionLoading,
    setAlertStatus,
  ]);

  const createSessionSuccessCallback = useCallback((sessionId?: number) => {
    createSuccessCallback();
    // todo for phone calls: stop getActiveSession timer
    if (sessionId) { // todo for phone calls: ask to return non-nullable value from backend
      timer.current = setInterval(() => {
        console.log("Phone call getSession");
        getSession({ variables: { sessionID: sessionId } });
      }, EXPERT_UPDATE_PHONE_SESSION_INFO_PERIOD_MS);
      marketingPhoneCallStartedHandler();
      saveUtmData({
        eventType: UtmAnalysisEventEnum.PhoneCallStarted,
        sessionID: sessionId,
      });
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [getSession, createSuccessCallback]);

  const createSessionFailCallback = useCallback((errorCodeList: number[]) => {
    createFailCallback(errorCodeList);
  }, [createFailCallback]);

  const [createSession] = useCreateSession({
    sessionType: SessionType.PHONE_CALL,
    rewriteUrl: false,
    successCallback: createSessionSuccessCallback,
    failCallback: createSessionFailCallback,
  });

  const createPhoneCall = useCallback((expertId: number) => {
    // todo for phone calls: is it ok to not to pass url parameters? maybe for metrics?
    createSession(expertId);
  }, [createSession]);

  return { createPhoneCall };
};

export default usePhoneCall;
