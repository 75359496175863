import { gql } from "@apollo/client";

export const GET_APPOINTMENT_PAGE_DATA = gql`
  query getAppointmentPageData($id: Int!) {
    getExpert(id: $id) {
      id
      name
      mobileImage: image(size: 46) {
        onex
        twox
        threex
      }
      desktopImage: image(size: 120) {
        onex
        twox
        threex
      }
      rates {
        videoCall
        phoneCall
        chat
      }
      userID
      trialAllowance {
        video
        chat
        phone
      }
    }
    getMyProfile {
      user {
        freeMinutesLeft
      }
      balance {
        amount
        currency
      }
      phone
    }
    getUserCards {
      id
      number
      type
      expiryMonth
      expiryYear
      isDefault
    }
  }
`;
