import React from "react";

import "./styles.scss";
import { Icon, IconSizeEnum, IconTypeEnum } from "../icon";

const PaymentNotification = () =>
  (
    <div className="payment-notification">
      <Icon type={IconTypeEnum.Like} size={IconSizeEnum.Size24} viewBox="24" />
      <p className="payment-notification__info">
        Завершайте разговор в любой
        <br />
        момент — неиспользованные деньги
        <br />
        останутся на балансе
      </p>
    </div>
  );

export default PaymentNotification;
