import React from "react";

import { IconSizeEnum } from "@/components/common/icon";
import { MIN_PAYMENT_AMOUNT } from "@/components/constants";
import { MIN_SESSION_DURATION } from "@/constants";
import { cardTypeToString } from "@/utils/globalTypesUtils";
import { getAvailableMinutes } from "@/utils/moneyUtils";
import { declenateWord } from "@/utils/stringUtils";

import CardIcon from "../../profile/balance/paymentMethods/cardIcon"; // todo: move cardIcon to common
import { getAppointmentPageData_getUserCards } from "../graphql/__generated__/getAppointmentPageData";

// todo: move to src/utils
export const cardToJsx = (card: getAppointmentPageData_getUserCards) =>
  ({
    value: card.id.toString(),
    label: (
      <div className="payment__option">
        <CardIcon type={card.type} size={IconSizeEnum.Size32} />
        {`${cardTypeToString(card.type)} • ${card.number}`}
      </div>
    ),
  });

export const isAmountValid = (
  amount: number,
  balance: number,
  callRate: number,
  freeMinutesCount: number,
) =>
  (
    !Number.isNaN(amount)
    && amount >= MIN_PAYMENT_AMOUNT
    && (amount + balance) / callRate >= MIN_SESSION_DURATION - freeMinutesCount
  );

// todo: move to component
export const getPaymentDescription = (
  balance: number,
  callRate: number,
  freeMinutesCount: number,
  isTrialAllowed: boolean,
) => {
  const minutesForBalance = getAvailableMinutes(balance, callRate);

  if (freeMinutesCount) {
    if (!balance) {
      return <></>;
    } if (balance < callRate * (MIN_SESSION_DURATION - freeMinutesCount)) {
      return (
        <>
          Минимальная длительность консультации —
          <br />
          {MIN_SESSION_DURATION}
          {" "}
          {declenateWord(MIN_SESSION_DURATION, ["минута", "минуты", "минут"])}
          . На вашем балансе
          недостаточно
          <br />
          средств, чтобы начать.
        </>
      );
    }
    return (
      <>
        <div>
          Вам хватит на
          {" "}
          <div className="payment__description-amount">
            {isTrialAllowed ? minutesForBalance + freeMinutesCount : minutesForBalance}
            {" "}
            {declenateWord(minutesForBalance + freeMinutesCount, ["минуту", "минуты", "минут"])}
          </div>
          {" "}
          разговора
        </div>
        {isTrialAllowed && (
          <div>
            включая
            {" "}
            {freeMinutesCount}
            {" "}
            {declenateWord(freeMinutesCount, ["бесплатную минуту", "бесплатные минуты", "бесплатных минут"])}
          </div>
        )}
        <div className="payment__hint">
          Если хотите поговорить дольше,
          <br />
          пополните баланс
        </div>
      </>
    );
  }
  if (!balance || balance < callRate * MIN_SESSION_DURATION) {
    return (
      <>
        Минимальная длительность консультации —
        <br />
        {MIN_SESSION_DURATION}
        {" "}
        {declenateWord(MIN_SESSION_DURATION, ["минута", "минуты", "минут"])}
        . На вашем балансе
        недостаточно
        <br />
        средств, чтобы начать.
      </>
    );
  }
  return (
    <>
      Вам хватит на
      {" "}
      <div className="payment__description-amount">
        {minutesForBalance}
        {" "}
        {declenateWord(minutesForBalance, ["минуту", "минуты", "минут"])}
      </div>
      {" "}
      разговора
      <div className="payment__hint">
        Если хотите поговорить дольше,
        <br />
        пополните баланс
      </div>
    </>
  );
};

// Функция для вычисления минимальной суммы пополнения
export const getMinimumTopUpAmount = (
  isTrialAllowed: boolean,
  freeMinutesCount:number,
  callRate: number,
  balance: number,
) => {
  // Если пробный период разрешен
  if (isTrialAllowed) {
    // Рассчитываем минимальную сумму пополнения, которая является максимальным значением из:
    // 1. Минимальной суммы платежа (MIN_PAYMENT_AMOUNT)
    // 2. Суммы, необходимой для покрытия оставшегося времени с учетом бесплатных минут и текущего баланса
    return Math.max(
      MIN_PAYMENT_AMOUNT,
      (MIN_SESSION_DURATION - freeMinutesCount) * callRate - balance,
    );
  }
  // Если пробный период не разрешен, рассчитываем сумму для
  // покрытия минимальной продолжительности сессии с учетом текущего баланса
  return MIN_SESSION_DURATION * callRate - balance;
};
